footer {
	background: $blue;
	color: $white;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 150px;
	position: relative;
	z-index: 10;

	#homepage & { 
		@media screen and (min-width: 600px) {
			display: none;
	} }

	#social-media-footer {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		margin-bottom: 15px;

		@keyframes bounce {
			0%, 100%, 20%, 50%, 80% {
				-webkit-transform: translateY(0);
				-ms-transform:     translateY(0);
				transform:         translateY(0)
			}
			40% {
				-webkit-transform: translateY(-5px);
				-ms-transform:     translateY(-5px);
				transform:         translateY(-5px)
			}
			60% {
				-webkit-transform: translateY(-2px);
				-ms-transform:     translateY(-2px);
				transform:         translateY(-2px)
			}
		}

		a {
			color: $white;
			font-size: 2rem;
			transition: .2s;
			margin: 0 10px;
			animation-duration: 1s;
			animation-fill-mode: both;
			animation-timing-function: ease-in-out;
			animation-iteration-count: infinite;

			&:hover {
				color: $pink;
				animation-name: bounce;
			}
		}
	}
	
	.copyright {
		color: $white;
		font-family: 'Poppins', sans-serif;
		font-size: .8rem;

		span {
			color: $pink;
		}

		.credit {
			text-decoration: none;
			color: $pink;
		}
	}
}




