body {
	font-size: calc(16px + 0.1vw);
	line-height: calc(1.1em + 0.5vw);
}

$lightpink: #FFACDB;
$white: #fff;
$pink: #D31682;
$blue: #151E46;
$turquoise: #45C1C0;
$lightpurple: #585F7B;

@import 'hamburger-menu';

@keyframes bounce {
	0%, 100%, 20%, 50%, 80% {
		-webkit-transform: translateY(0);
		-ms-transform:     translateY(0);
		transform:         translateY(0)
	}
	40% {
		-webkit-transform: translateY(-5px);
		-ms-transform:     translateY(-5px);
		transform:         translateY(-5px)
	}
	60% {
		-webkit-transform: translateY(-2px);
		-ms-transform:     translateY(-2px);
		transform:         translateY(-2px)
	}
}

.work-title {
	display: flex;
	background: $turquoise;
	color: $white;
	font-size: 2rem;
	font-family: 'Poppins', sans-serif;
	width: 100%;
	box-shadow: 0px 2px 33px 10px rgba(204,204,204,1);

	a {
		color: $white;
		padding: 10px;
		margin-left: 5%;
		text-decoration: none;
	}

	@media screen and (min-width: 600px) {

		font-size: 2.2rem;

		a {
			color: $white;
			padding: 15px;
			text-decoration: none;
		}
	}
}

.square-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-family: 'Noto Serif', serif;
	line-height: 25px;
}

.portfolio-square {
	margin-top: 35vh;
}

.work-content {
	position: relative;
	max-width: 1500px;
	left: 50%;
	transform: translateX(-50%);


	.portfolio {
		display: flex;
		flex-direction: column;
		align-items: center;

		@media screen and (min-width: 600px) {
			flex-direction: row;
			flex-wrap: wrap;
			width: 70%;
		}

		a {
			text-decoration: none;
		}
	}

	.thumb {
	    width: 300px;
	    height: 300px;
	    margin: 70px auto;
	    perspective: 1000px;
	    // transform: rotate(-12deg);

    	// @media screen and (min-width: 900px) {
     //    	transform: rotate(-7deg);
    	// }
	}

	.thumb a {
	    display: block;
	    width: 100%;
	    height: 100%;
	    background-size: 0, cover;
	    transform-style: preserve-3d;
	    transition: all 0.5s;
	}

	.cheesemonger a {
	    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), 
		url("../img/image_cheesemonger.png");
		background-size: 0, cover;
		background-position: center;
	    transform-style: preserve-3d;
	    transition: all 0.5s;
	}

	.anatomy-slider a {
	    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), 
		url("../img/anatomy-slider1.png");
		background-size: 0, cover;
		background-position: center;
	    transform-style: preserve-3d;
	    transition: all 0.5s;
	}

	.giphy-battle a {
	    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), 
		url("../img/giphy-battle.png");
		background-size: 0, cover;
		background-position: center;
	    transform-style: preserve-3d;
	    transition: all 0.5s;
	}

	.daily-digits a {
	    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), 
		url("../img/image_daily_digits.png");
		background-size: 0, cover;
	    background-position: 66% 1px;
	    transform-style: preserve-3d;
	    transition: all 0.5s;
	}

	.mercedes-benz a {
	    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), 
		url("../img/mercedes-benz.png");
		background-size: 0, cover;
		background-position: center;
	    transform-style: preserve-3d;
	    transition: all 0.5s;
	}

	.red-socks a {
	    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), 
		url("../img/red-socks.png");
		background-size: 0, cover;
		background-position: center;
	    transform-style: preserve-3d;
	    transition: all 0.5s;
	}

	.stranger-things a {
	    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), 
		url("../img/stranger-things.png");
		background-size: 0, cover;
	    background-position: 45% -15px;
	    transform-style: preserve-3d;
	    transition: all 0.5s;

	    @media screen and (min-width: 600px) {
	    	background-position: 45% -40px;
	    }
	}

	.marta-redesign a {
	    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), 
		url("../img/marta-redesign2.png");
		background-size: 0, cover;
		background-position: center;
	    transform-style: preserve-3d;
	    transition: all 0.5s;
	}

	.furniture-world a {
	    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), 
		url("../img/furniture-world2.png");
		background-size: 0, cover;
		background-position: center;
	    transform-style: preserve-3d;
	    transition: all 0.5s;
	}



	.thumb:hover a {
	    transform: rotateX(80deg);
	    transform-origin: bottom;
	}
	.thumb a:after {
	    content: '';
	    position: absolute;
	    left: 0;
	    bottom: 0;
	    width: 100%;
	    height: 36px;
	    background: inherit;
	    background-size: cover, cover;
	    background-position: bottom;
	    transform: rotateX(90deg);
	    transform-origin: bottom;
	}
	.thumb a span {
	    color: $white;
	    background: $turquoise;
	    text-transform: uppercase;
	    position: absolute;
	    top: 100%;
	    left: 0;
	    width: 100%;
	    font-family: 'Poppins', sans-serif;
	    text-align: center;
	    transform: rotateX(-89.99deg);
	    transform-origin: top;
	    z-index: 1;
	}
	.thumb a:before {
	    content: '';
	    position: absolute;
	    top: -20px;
	    left: 0;
	    width: 100%;
	    height: 100%;
	    background: rgba(0, 0, 0, 0.5);
	    box-shadow: 0 0 100px 50px rgba(0, 0, 0, 0.5);
	    transition: all 0.5s;
	    opacity: 0.15;
	    transform: rotateX(95deg) translateZ(-80px) scale(0.75);
	    transform-origin: bottom;
	}

	.thumb:hover a:before {
	    opacity: 1;
	    box-shadow: 0 0 25px 25px rgba(0, 0, 0, 0.5);
	    transform: rotateX(0) translateZ(-60px) scale(0.85);
	}
}




