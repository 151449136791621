.contact-title {
	display: flex;
	background: $blue;
	color: $white;
	font-size: 2rem;
	font-family: 'Poppins', sans-serif;
	width: 100%;
	box-shadow: 0px 2px 33px 10px rgba(204,204,204,1);

	a {
		color: $white;
		padding: 10px;
		margin-left: 5%;
		text-decoration: none;
	}

	@media screen and (min-width: 600px) {

		font-size: 2.2rem;

		a {
			color: $white;
			padding: 15px;
			text-decoration: none;
		}

	}
}

.contact-square {
	margin-top: 35vh;
}

.call-to-action {
	// color: $turquoise;
	color: $pink;
	font-family: 'Poppins', sans-serif;
	margin-top: 10%;
	margin-bottom: 10%;
	font-size: calc(.5em + 3vw);
	line-height: calc(1.1em + 0.5vw);
	width: 70%;
	text-align: center;
}

.contact-content {
	position: relative;
	max-width: 1500px;
	left: 50%;
	transform: translateX(-50%);
	background: $white;

	.contact-form {
		color: $blue;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: center;
		margin-bottom: 5vh;
		width: 80%;

		input:focus,
		select:focus,
		textarea:focus,
		button:focus {
		    outline: none;
		}

		input::placeholder,
		textarea::placeholder {
		  color: $white;
		  opacity: .6;
		  font-size: 1rem;
		}

		input,
		textarea {
			font-family: 'Poppins', sans-serif;
			background: $blue;
			border: none;
			width: 100%;
			color: $white;
			font-size: 1.5rem;
			margin-bottom: 10%;
			padding-left: 10px;

			@media screen and (min-width: 600px) {
				width: 50vw;
				margin-bottom: 5%;
			}

			@media screen and (min-width: 900px) {
				height: 10vh;
			}
		}

		textarea {
			height: 20vh;

			@media screen and (min-width: 900px) {
				height: 30vh;
			}
		}

		label {
			font-family: 'Poppins', sans-serif;
			display: flex;
			flex-direction: column;
			color: $turquoise;
			font-size: 1.2rem;
		}

		.form-button {
			color: $pink;
			font-family: 'Poppins', sans-serif;
			font-size: 2em;
			cursor: pointer;
			display: inline-block;
			position: relative;
			margin-bottom: 0.5em;
			background: none;
			border: none;
		}

		.form-button.anim-destination {
			display: inline-block;
			overflow: hidden;
			padding-right: 0.5em;
			font-size: 2em;
		}

		.form-button.anim-destination span {
			position: relative;
			display: inline-block;
			-webkit-transition: -webkit-transform 0.3s;
			transition: transform 0.3s;
			padding: 5px;
		}

		.form-button.anim-destination span::before {
			position: absolute;
			white-space: nowrap;	
			top: 115%;
			content: attr(data-hover);
		}

		.form-button.anim-destination:hover span,
		.form-button.anim-destination:focus span {
			-webkit-transform: translateY(-100%);
			transform: translateY(-100%);
			color: $pink;
		}

		.thank-you-php {
			color: $pink;
			font-family: 'Poppins', sans-serif;
			font-size: calc(.5em + 3vw);
			line-height: calc(1.1em + 0.5vw);
			width: 70%;
			text-align: center;
			display: none;

			&.active {
				display: block;
			}
		}
	}
}