$lightpink: #FFACDB;
$white: #fff;
$pink: #D31682;
$blue: #151E46;
$turquoise: #45C1C0;
$lightpurple: #585F7B;

@keyframes bounce {
	0%, 100%, 20%, 50%, 80% {
		-webkit-transform: translateY(0);
		-ms-transform:     translateY(0);
		transform:         translateY(0)
	}
	40% {
		-webkit-transform: translateY(-5px);
		-ms-transform:     translateY(-5px);
		transform:         translateY(-5px)
	}
	60% {
		-webkit-transform: translateY(-2px);
		-ms-transform:     translateY(-2px);
		transform:         translateY(-2px)
	}
}

@import 'reset';
@import 'nav-bar';
@import 'hamburger-menu';
@import 'header';
@import 'footer';

@import 'work';
@import 'contact';
@import 'about';

@import 'portfolio-pieces';

/* 1. Main Layout ================================= */

* {
	box-sizing: border-box;
}

[name="honeypot"] {
	display: none;
}

body {
	// background-image: url(../img/dust.png);
	background: #fff;
	width: 100%;
	height: 100%;
	overflow-anchor: none;
	position: absolute;

	&#homepage { 
		@media screen and (min-width: 600px) {
			overflow: hidden;

			.desktop-nav {
				display: none;
			}
	} }
}

.logo-desktop {
	@media screen and (min-width: 600px) {
		background-image: url("/dist/img/logo.svg");
		top: 23vh;
		left: 2vw;
		position: absolute;

		&:hover {
			animation: none;
			transition: none;
			top: 15vh;
			transform: translateY(0);
		}
	}

	@media screen and (min-width: 900px) {
		width: 24%;
		height: 39vh;
		top: 15vh;
		position: fixed;
	}
}

.squares {
	position: relative;
	display: flex;
	flex-direction: column;
	left: 50%;
	transform: translateX(-50%);

	@media screen and (min-width: 600px) {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		transform: rotate(-30deg)scale(2);
		overflow: hidden;
		left: 0;
	}

	@keyframes bounce {
		0%, 100%, 20%, 50%, 80% {
			-webkit-transform: translateY(0);
			-ms-transform:     translateY(0);
			transform:         translateY(0)
		}
		40% {
			-webkit-transform: translateY(-5px);
			-ms-transform:     translateY(-5px);
			transform:         translateY(-5px)
		}
		60% {
			-webkit-transform: translateY(-2px);
			-ms-transform:     translateY(-2px);
			transform:         translateY(-2px)
		}
	}

	.square-title {
		position: relative;
		display: flex;
		flex-direction: column;
		background: lightblue;
		color: $white;
		font-family: 'Poppins', sans-serif;
		font-size: 2rem;
		padding: 3% 5%;
		display: none;

		@media screen and (min-width: 600px) {
			display: flex;
			width: 49.25%;
			height: 49.25vh;
			margin: 0.5%;
			font-size: 1rem;
			padding: 20px;
			transform: rotateY(3deg);

			a {
				text-decoration: none;
				color: $white;
				animation-duration: 1s;
				animation-fill-mode: both;
				animation-timing-function: ease-in-out;
				animation-iteration-count: infinite;

				&:hover {
					animation-name: bounce;
				}
			}
		}
	}

	.square1 {
		display: flex;
		flex-direction: row-reverse;
		align-items: flex-end;
		background: transparent;
		margin-right: 0.25%;
		margin-bottom: 0.25vh;
		margin-top: 10vh;

		@media screen and (min-width: 600px) {
			margin-top: 0;
		}

		#social-media-header {
			display: none;

			@media screen and (min-width: 600px) {
				display: flex;

				a {
					color: $blue;
					transition: .2s;
					margin: 0 10px;

					&:hover {
						color: $pink;
					}
				}
			}
		}
	}

	.square2 {
		display: none;

		@media screen and (min-width: 600px) {
			background: $turquoise;
			display: flex;
			flex-direction: column-reverse;
			margin-left: 0.25%;
			margin-bottom: 0.5vh;
		}
	}

	.square3 {
		display: none;

		@media screen and (min-width: 600px) {
			display: flex;
			background: $pink;
			display: flex;
			flex-direction: row-reverse;
			margin-right: 0.25%;
			margin-top: 0.25vh;
		}
	}

	.square4 {
		display: none;

		@media screen and (min-width: 600px) {
			display: flex; 
			background: $blue;
			margin-left: 0.25%;
			margin-top: 0.25vh;
		}
	}

	//// Individual Content for Squares

	.square-content {
		display: flex;
		flex-direction: column;
		align-items: center;
		font-family: 'Noto Serif', serif;
		// background: $white;
		line-height: 25px;
		padding: 5%;

		@media screen and (min-width: 600px) {
			display: none;
		}
	}

	.work-content {
		position: relative;

		.index-work-name {
			color: #fff;
			font-size: 2rem;
			font-family: 'Poppins', sans-serif;
			position: absolute;
			transform-origin: bottom right;
			transform: rotate(-12deg);
			top: -72px;
			right: -254px;
			padding: 10px;
			padding-right: 360px;
			width: 2000px;
			text-align: right;
			position: fixed;
			background: $turquoise;
			position: absolute;

			@media screen and (min-width: 600px) {
				display: none;
			}
		}



		.portfolio {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-top: 5vh;

			a {
				text-decoration: none;
			}
		}

		.thumb {
		    width: 300px;
		    height: 300px;
		    margin: 70px auto;
		    perspective: 1000px;
		}

		.thumb a {
		    display: block;
		    width: 100%;
		    height: 100%;
		    background-size: 0, cover;
		    transform-style: preserve-3d;
		    transition: all 0.5s;
		}

		.cheesemonger a {
		    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), 
			url("../img/image_cheesemonger.png");
			background-size: 0, cover;
			background-position: center;
		    transform-style: preserve-3d;
		    transition: all 0.5s;
		}

		.anatomy-slider a {
		    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), 
			url("../img/anatomy-slider2.png");
			background-size: 0, cover;
			background-position: center;
		    transform-style: preserve-3d;
		    transition: all 0.5s;
		}

		.giphy-battle a {
		    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), 
			url("../img/giphy-battle.png");
			background-size: 0, cover;
			background-position: center;
		    transform-style: preserve-3d;
		    transition: all 0.5s;
		}

		.daily-digits a {
		    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), 
			url("../img/image_daily_digits.png");
			background-size: 0, cover;
		    background-position: 66% 1px;
		    transform-style: preserve-3d;
		    transition: all 0.5s;
		}

		.mercedes-benz a {
		    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), 
			url("../img/mercedes-benz.png");
			background-size: 0, cover;
			background-position: center;
		    transform-style: preserve-3d;
		    transition: all 0.5s;
		}

		.red-socks a {
		    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), 
			url("../img/red-socks.png");
			background-size: 0, cover;
			background-position: center;
		    transform-style: preserve-3d;
		    transition: all 0.5s;
		}

		.stranger-things a {
		    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), 
			url("../img/stranger-things.png");
			background-size: 0, cover;
		    background-position: 45% -15px;
		    transform-style: preserve-3d;
		    transition: all 0.5s;
		}

		.marta-redesign a {
		    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), 
			url("../img/marta-redesign2.png");
			background-size: 0, cover;
			background-position: center;
		    transform-style: preserve-3d;
		    transition: all 0.5s;
		}

		.furniture-world a {
		    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), 
			url("../img/furniture-world2.png");
			background-size: 0, cover;
			background-position: center;
		    transform-style: preserve-3d;
		    transition: all 0.5s;
		}



		.thumb:hover a {
		    transform: rotateX(80deg);
		    transform-origin: bottom;
		}
		.thumb a:after {
		    content: '';
		    position: absolute;
		    left: 0;
		    bottom: 0;
		    width: 100%;
		    height: 36px;
		    background: inherit;
		    background-size: cover, cover;
		    background-position: bottom;
		    transform: rotateX(90deg);
		    transform-origin: bottom;
		}
		.thumb a span {
		    color: $white;
		    background: $turquoise;
		    text-transform: uppercase;
		    position: absolute;
		    top: 100%;
		    left: 0;
		    width: 100%;
		    font-family: 'Poppins', sans-serif;
		    text-align: center;
		    transform: rotateX(-89.99deg);
		    transform-origin: top;
		    z-index: 1;
		}
		.thumb a:before {
		    content: '';
		    position: absolute;
		    top: -20px;
		    left: 0;
		    width: 100%;
		    height: 100%;
		    background: rgba(0, 0, 0, 0.5);
		    box-shadow: 0 0 100px 50px rgba(0, 0, 0, 0.5);
		    transition: all 0.5s;
		    opacity: 0.15;
		    transform: rotateX(95deg) translateZ(-80px) scale(0.75);
		    transform-origin: bottom;
		}

		.thumb:hover a:before {
		    opacity: 1;
		    box-shadow: 0 0 25px 25px rgba(0, 0, 0, 0.5);
		    transform: rotateX(0) translateZ(-60px) scale(0.85);
		}
	}

	.about-content {
		position: relative;

		.index-about-name {
			color: #fff;
			font-size: 2rem;
			font-family: 'Poppins', sans-serif;
			position: absolute;
			transform-origin: bottom right;
			transform: rotate(-12deg);
			top: -90px;
			right: -254px;
			padding: 10px;
			padding-right: 360px;
			width: 2000px;
			text-align: right;
			position: fixed;
			background: $pink;
			position: absolute;

			@media screen and (min-width: 600px) {
				display: none;
			}
		}


		color: $blue;
		font-family: 'Poppins', sans-serif;

		.image-shelly {
			background-image: url(../img/shelly.jpg);
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center 10%;
			width: 100%;
			height: 30vh;
			margin-bottom: 10vh;
		    margin-top: 14vh;
			box-shadow: 0px 2px 33px 10px rgba(204,204,204,1);
		}

		h1 {
			color: $pink;
			font-size: 3rem;
			margin-bottom: 5vh;
		}

		p {
			margin-bottom: 3vh;

			span {
				color: $pink;
				font-weight: bold;
			}
		}

		.bio {
			padding: 0 5%;
		}
	}

	.contact-content {
		position: relative;

		.index-contact-name {
			color: #fff;
			font-size: 2rem;
			font-family: 'Poppins', sans-serif;
			position: absolute;
			transform-origin: bottom right;
			transform: rotate(-12deg);
			top: -122px;
			right: -254px;
			padding: 10px;
			padding-right: 360px;
			width: 2000px;
			text-align: right;
			position: fixed;
			background: $blue;
			position: absolute;

			@media screen and (min-width: 600px) {
				display: none;
			}
		}
	}
}







