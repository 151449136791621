.nav {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	padding: 15vh 0;
	align-items: center;
	background: $pink;
	opacity: .8;
	width: 100%;
	height: 100vh;
	position: absolute;
	transform: translateY(-105%);
	transition: .4s all;

	@media screen and (min-width: 600px) {
		display: none;
	}

	&.active {
		transform: translateY(0%);
	}

	.nav-link {
		color: $white;
		font-family: 'Poppins', sans-serif;
		font-size: 2em;
		cursor: pointer;
		display: inline-block;
		position: relative;
	}

	.nav-link.anim-destination {
		display: inline-block;
		overflow: hidden;
		// padding-right: 0.5em;
		font-size: 2em;
	}

	.nav-link.anim-destination span {
		position: relative;
		display: inline-block;
		-webkit-transition: -webkit-transform 0.3s;
		transition: transform 0.3s;
		padding: 5px;
	}

	.nav-link.anim-destination span::before {
		position: absolute;
		white-space: nowrap;	
		top: 115%;
		content: attr(data-hover);
		font-weight: 500;
	}

	.nav-link.anim-destination:hover span,
	.nav-link.anim-destination:focus span {
		-webkit-transform: translateY(-100%);
		transform: translateY(-100%);
		color: $blue;
	}
}
