.work-piece {
	margin-top: 30vh;

	@media screen and (min-width: 600px) {
		margin-top: 35vh;
	}
}

.overlay {
	// margin-top: 30vh;
	position: relative;
	// width: 100%;
	z-index: 5;
	display: flex;
	flex-direction: column;
	// align-items: center;

	.portfolio-piece {
		align-self: center;
	    width: 84vw;
        height: 30vh;
	    perspective: 1000px;
	    position: relative;
        z-index: 1;
        transform: rotate(-12deg);
        margin-left: -30%;

        @media screen and (min-width: 600px) {
        	height: 40vh;
        	margin-bottom: 0;
        	margin-left: -10%;
        }

        @media screen and (min-width: 900px) {

        }

        @media screen and (min-width: 1200px) {
        	transform: rotate(-7deg);
        }
	}

	.portfolio-piece a {
	    display: block;
	    width: 100%;
	    height: 100%;
	    background-size: 0, cover;
	    transform-style: preserve-3d;
	    transition: all 0.5s;
	}

	.portfolio-piece a:after {
	    content: '';
	    position: absolute;
	    left: 0;
	    bottom: 0;
	    width: 100%;
	    height: 36px;
	    background: inherit;
	    background-size: cover, cover;
	    background-position: bottom;
	    transform: rotateX(90deg);
	    transform-origin: bottom;
	}
	.portfolio-piece a span {
	    color: $white;
	    background: $turquoise;
	    text-transform: uppercase;
	    position: absolute;
	    top: 100%;
	    left: 0;
	    width: 100%;
	    font-family: 'Poppins', sans-serif;
	    text-align: center;
	    transform: rotateX(-89.99deg);
	    transform-origin: top;
	    z-index: 1;
	}

	.portfolio-piece:hover a:before {
	    opacity: 1;
	    box-shadow: 0 0 25px 25px rgba(0, 0, 0, 0.5);
	    transform: rotateX(0) translateZ(-60px) scale(0.85);
	}

	.overlay-text {
		transform: rotate(-12deg);
		margin-bottom: 10vh;
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;

		@media screen and (min-width: 1200px) {
			transform: rotate(-7deg);
		}

		.text {
			width: 70vw;
			margin-left: 15%;
			margin-top: 10%;
			color: $blue;
			display: flex;
			flex-direction: column;

			.text-title {
				font-size: calc(1em + 3vw);
				line-height: calc(.8em + 0.5vw);
				margin-bottom: 10%;

				@media screen and (min-width: 600px) {
					margin-bottom: 5%;
				}
			}

			.text-description {
				font-size: 1rem;
				line-height: 1.3rem;
				margin-bottom: 5%;

				@media screen and (min-width: 600px) {
					font-size: 1.2rem;
					line-height: 1.4rem;
					margin-bottom: 5%;
				}
			}

			.copyright-designer {
				margin-bottom: 5%;

				& a {
					color: $pink;
					text-decoration: none;
				}
			}

			.text-technology {
				font-size: 1.2rem;
				margin-bottom: 10%;

				@media screen and (min-width: 600px) {
					font-size: 1.5rem;
					// margin-bottom: 5%;
				}

				& h2 {
					margin-bottom: 10px;
				}

				.technology-array {
					list-style-image: url(../img/furball_black.svg);
					display: flex;
					flex-direction: column;
					font-size: 1rem;
					line-height: 1.2rem;

					li {
						margin-left: 5%;
					}

					@media screen and (min-width: 600px) {
						font-size: 1.2rem;
						line-height: 1.5rem;
					}
				}

				@media screen and (min-width: 600px) {
					margin-bottom: 5%;
				}
			}

			.text-link {
				font-size: calc(.3em + 3vw);
				text-decoration: none;
				color: $turquoise;
				font-size: 2em;
				font-weight: 300;
				cursor: pointer;
				display: inline-block;
				position: relative;
				margin-bottom: 5%;
				align-self: flex-end;
			}

			.text-link.anim-destination {
				display: inline-block;
				overflow: hidden;
				padding-right: 0.5em;
				font-size: 2em;
			}

			.text-link.anim-destination span {
				position: relative;
				display: inline-block;
				-webkit-transition: -webkit-transform 0.3s;
				transition: transform 0.3s;
				padding: 10px;

			}

			.text-link.anim-destination span::before {
				position: absolute;
				white-space: nowrap;	
				top: 105%;
				content: attr(data-hover);
				font-weight: 500;
				padding: 10px;
			}

			.text-link.anim-destination:hover span,
			.text-link.anim-destination:focus span {
				-webkit-transform: translateY(-105%);
				transform: translateY(-105%);
				color: $pink;
			}
		}
	}
}



