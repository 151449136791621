$bar-width: 50px;
$bar-height: 5px;
$bar-spacing: 12px;


.hamburger-menu {
	position: absolute;
	z-index: 10;
	top: 45px;
	right: 40px;
	margin: auto;
	width: $bar-width;
	height: $bar-height + $bar-spacing*2;
	cursor: pointer;

	@media screen and (min-width: 600px) {
		display: none;
	}
}

.bar,
.bar:after,
.bar:before {
	width: $bar-width;
	height: $bar-height;
}

.bar {
	position: relative;
	transform: translateY($bar-spacing);
	background: $white;
	transform: rotate(-12deg);
	transition: all 0ms 300ms;

	&.animate {
		background: rgba(255, 255, 255, 0); 
	}
}

.bar:before {
	content: "";
	position: absolute;
	left: 0;
	bottom: $bar-spacing;
	background: $white;
	transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.bar:after {
	content: "";
	position: absolute;
	left: 0;
	top: $bar-spacing;
	background: $white;
	transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.bar.animate:after {
	top: 0;
	transform: rotate(45deg);
	transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);;
}

.bar.animate:before {
	bottom: 0;
	transform: rotate(-45deg);
	transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);;
}
