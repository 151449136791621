.square-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-family: 'Noto Serif', serif;
	line-height: 25px;
}	

.about-content {
	position: relative;
	color: $blue;
	font-family: 'Poppins', sans-serif;

	.image-shelly {
		background-image: url(../img/shelly.jpg);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center 10%;
		align-self: center;
	    width: 84vw;
        height: 30vh;
        margin-bottom: 5vh;

        @media screen and (min-width:600px) {
        	height: 40vh;
        }

        @media screen and (min-width:900px) {
        	width: 70vw;
        	height: 50vh;
        }
	}


	h1 {
		color: $pink;
		font-size: 3rem;
		margin-bottom: 10vh;
	}



	.bio {
		padding: 0 10%;
		margin-bottom: 5vh;

		p {
			margin-bottom: 3vh;

			span {
				color: $pink;
				font-weight: bold;
			}
		}

		@media screen and (min-width: 600px) {

		}
	}

	.resume {
		font-size: 1.2rem;
		color: $pink;
		margin-bottom: 10vh;

		@keyframes bounce {
			0%, 100%, 20%, 50%, 80% {
				-webkit-transform: translateY(0);
				-ms-transform:     translateY(0);
				transform:         translateY(0)
			}
			40% {
				-webkit-transform: translateY(-5px);
				-ms-transform:     translateY(-5px);
				transform:         translateY(-5px)
			}
			60% {
				-webkit-transform: translateY(-2px);
				-ms-transform:     translateY(-2px);
				transform:         translateY(-2px)
			}
		}

		a {
			transition: .2s;
			margin: 0 10px;
			animation-duration: 1s;
			animation-fill-mode: both;
			animation-timing-function: ease-in-out;
			animation-iteration-count: infinite;

			& svg {
				color: $pink;
				font-size: 2rem;
				margin-left: 10px;
			}

			&:hover {
				animation-name: bounce;
			}
		}
	}

}

